/* src/App.css */

/*
.container {
  display: flex;
  flex-direction: row;
  gap: 20px; 
  padding: 20px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1; 
  min-width: 100px; 
}
*/

.box {
  border: 2px dashed black;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.05);
  width: 100%; /* Makes the box take up the full width of its parent column */
}

/* Table Styles */
.table {
  margin: 5px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  max-height: 100%;
}

.table thead {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  margin: 2px 0; 
}

.table th,
.table td {
  border: 1px solid black; 
  padding: 4px 4px;
}

.table td {
  border-bottom: 1px solid #dddddd;
}

.table tr:hover {
  background-color: #f3f3f3;
}



/* Styles for table container   */
.table-container {
  overflow: auto;
  max-width: 100%;
  height: 100%;
}

.table thead th {
  position: sticky;
  top: 0;
  z-index: 100; 
  background-color: #009879; }

  .scrollable-container {
    overflow-y: auto; /* Enable scrolling */
    /* Additional styles for the scrollable area */
}

.table-title {
	margin: 2px 0; /* Adjust the top and bottom margin as needed */
	text-align: center;
}

.chart-fixed {
  position: fixed !important;;
  top: 0 !important;;
  left: 0 !important;;
  z-index: 1000 !important;;  /* Ensure it's on top */
}